import React from "react"

const Voice = ({ title, from, children }) => {
    return (
        <div className="voice-wrapper">
            <h4 className="line">
                {title}
            </h4>
            <div className="message">
                {children}
            </div>
            <div className="from">
                {from}
            </div>
        </div>
    )
}

export default Voice