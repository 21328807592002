import React from "react"
import PageLayout from "../components/page-layout"
import Voice from "../components/voice"

export default function Home() {
    return (
        <PageLayout title="保護者さまの声">
            <div className="voice">
                <div className="voice-container">
                    <h3 id="child">子どもクラス</h3>
                    <Voice title="日々、娘の成長を感じる事が出来、とても感謝しています" from="プライマリークラスの保護者さま">
                        {`娘が3歳になり、初めての習い事が YUKI MAMIYA バレエスタジオでした。
                        まだ幼稚園にも通っておらず、離れて過ごす初めての経験だったので、最初のレッスンでは最後までレッスン出来るだろうかと心配していたのですが、お迎えに行くと満面の笑みで、帰り道では『もっとレッスンしたかった!!』と嬉しそうに話してくれ、ホッとしたのを覚えています。
                        今でも時折レッスン前に、少し機嫌が悪い時があるのですが、そんな日もお迎えに行くと必ず笑顔になっていて、先生とのレッスンを心から楽しんでいるようです。
                        また、レッスンの準備や帰り支度の際、まだ上手く出来ず時間がとてもかかってしまうのですが、先生は娘が落ち着いて出来るよう、急かす事なく静かに優しく見守って下さり、自立心を上手く育む様、接して下さいます。
                        レッスンを通じて挨拶や話を聴く姿勢、マナー等を小さな頃から身に着けられる様、優しさの中にもしっかりと減り張りをつけた指導で、娘の長所を上手く伸ばして下さり、日々、娘の成長を感じる事が出来、とても感謝しています。
                        初めてのおさらい会では、ただただ無邪気に踊っていたのが、今回の発表会では、今迄にない緊張感に加えて、向上心、自尊心に芽生えた様子を感じ取る事ができ、一年でこんなに変わるのか、と著しい成長にとても感動しました。
                        バレエを通じて、『上達する為に努力する』という事を学び、『達成感を得る喜び』を得、どんどん成長していく姿をこれからも見守り続けて行きたいと思います。`}
                    </Voice>

                    <Voice title="娘が目を輝かせながら喜びを伝えてくれました" from="プライマリークラスの保護者さま">
                        {`私が生まれ育った小さな町には、バレエ教室がありませんでした。いつか娘が生まれたら…、そんな思いがずっとありました。
                        娘が2歳の頃、バレエのドラマを観て、「バレエをやりたい！」と、言い出しました。どうやらフリフリの衣装を着たくなってしまったようでした。
                        3歳になっても気持ちが変わらなかったので、お友達に紹介してもらい、眞宮先生に出会う事ができました。
                        ありのままの娘を優しく受け入れて下さる先生ですが、楽しいレッスンの中でも、礼節、マナーを丁寧に教えて下さいます。
                        また、同じクラスのお友達も皆仲が良く、幼稚園とは違う楽しさと一体感があるようです。
                        先日の発表会では、「みんなで舞台に立てた事が嬉しかったの。もっともっと練習して、お姉さん達のように踊りたい！」と、目を輝かせながら喜びを伝えてくれました。
                        心の成長も感じます。
                        寄り添って指導して下さる眞宮先生には、感謝の気持ちで一杯です。
                        バレエのレッスンの日は、レオタードに着替え、キュッと髪の毛をお団子にしてあげると、鏡の前に走って行き、ニコッとする娘。
                        バレエの衣装を着るという夢を叶えた今、次の夢は、「硬い靴（トゥシューズ）を履く事！」だそうです。
                        素晴らしい先生に教えていただける環境に感謝して、そして楽しみながら練習に励んで欲しいと思います。
                        娘の成長がますます楽しみです。`}
                    </Voice>

                    <Voice title="子供達の各々の特性を理解した上で、きちんと指導して下さいます" from="ジュニアクラスの保護者さま">
                        {`娘は小学3年生からバレエを始めました。美しく清潔感のあるスタジオでレッスンを受け、身体の柔軟性が増し、普段の姿勢もよくなりました。父親は、身のこなしが美しくなったと、バレエを始めた効果を実感しているようです。
                        娘は、レッスンでバレエの技術だけではなく、ご挨拶の大切さや心構えについても学んでいます。仲間の良い所を見習い、お姉様方の礼節と優しさに憧れて、心身の成長につながる刺激を頂いています。先生は凛として美しく、母親である私が憧れてしまうような先生です。子供達の各々の特性を理解した上で、きちんと指導して下さいます。発表会では、本格的なバレエの衣装を身に着けた娘の姿をみて、祖父母も大喜びでした。この教室でバレエを始めることができてよかったと家族共々感謝しています。`}
                    </Voice>

                    <Voice title="子供達が一つの作品を一緒に作り上げる素敵なバレエ教室です" from="ジュニアクラスの保護者さま">
                        {`上の子が習っていたバレエの発表会を見て、「私もやりたい！」と始めたバレエ。
                        ちょうど引っ越しもあり、環境も変わったので続けるのか正直分からない程でした。
                        ですが、先生がとても良く子供の様子、性格まで見て下さり、すぐに憧れていたトゥーシューズを履く準備や筋トレ方法等を教えて下さいました。
                        子供の「やりたい！」という気持ちをすぐにくみ取り、タイミング良く始めて頂いたのでバレエが大好きになりそこから「もっとやってみたい！」という気持ちになっていきました。
                        習い事をいくつかやりましたが、ここまで子供の気持ち、やる気を見て下さりタイミングを逃さずやる気を育てて下さる教室はありませんでした。
                        特に、バレエというと発表会のお手伝い等が大変なイメージがありますが、眞宮先生の教室は、そんなお手伝い等なく子供達が一つの作品を一緒に作り上げる素敵なバレエ教室です。`}
                    </Voice>

                    <Voice title="お教室の雰囲気もとても温かく、安心して娘たちを預けています" from="ジュニアクラスの保護者さま">
                        {`姉妹で眞宮先生にお世話になっています。
                        プライマリーAクラスに通う娘にはバレエの基礎を一から丁寧に教えていただくだけでなく、踊ることの楽しさを。
                        また、ジュニアBクラスに通う娘には基礎に加えてテクニックや表現力などを。
                        この他お稽古場でのご挨拶等も含めて一人一人にしっかり向き合って、丁寧にご指導いただいています。
                        お教室の雰囲気もとても温かく、安心して娘たちを預けています。
                        娘たちもレッスンが楽しくてたまらないようで、新しいことを教わるとお家で練習していて、子供たちの向上心も上手に育ててくださっていると思います。
                        娘2人とも別のお教室に通っていましたが、眞宮先生のお教室に出会って本当に良かったと感じています。`}
                    </Voice>

                    <Voice title="一番の大きな変化は、「バレエが大好き！」になったことです" from="ジュニアクラスの保護者さま">
                        {`お教室ができた当初から姉妹で通っており、2年ほどになります。入会して一番の大きな変化は、2人とも「バレエが大好き！」になったことです。レッスンがない日も、登校前に早く帰宅しバレエごっこをする約束をしています。長女は、自らレッスン回数を増やすことも決め、レッスン後に、出来なかったことをバレエノートに纏めたり、復習している姿をよく目にします。念願のトウシューズのお許しが出た時は、その喜びように私も大変嬉しくなりました。次女もお姉ちゃんのトウシューズに憧れ、レッスンを増やすことを決めました。バレエを通して、お友達と切磋琢磨しながら、努力することの大切さや、達成する喜びを学んでいるようです。これも熱心に、時に厳しく、愛情もってご指導くださる眞宮先生のおかげです。益々2人の成長が楽しみです。よろしくお願いいたします。`}
                    </Voice>

                    <h3 id="takarazuka">宝塚受験クラス</h3>
                    <Voice title="スタジオ開設から1年半しか経っていないとは思えない完成度でした" from="宝塚受験クラス・合格者の保護者さま">
                        {`主宰の眞宮由妃先生との出会いは娘が4歳の時でした。以前通っていたバレエスクールの時から、先生は娘の性格や苦手なことを見抜いた上で的確にご指導下さっていました。時には厳しい事も言われてまいりましたが、その中にある愛情が幼い娘にも伝わっていたからこそ、今までバレエを辞めずに続けることができたと思っております。
                            娘は小さな頃から踊ったり歌ったりするのが大好きで、中学に入り宝塚に憧れ始めた時、一番初めに相談したのは先生でした。その時先生が新しくバレエスタジオを開設すると伺い、何の迷いもなく親子でついて来ました。先生との出会いがなければ、宝塚という夢の世界はただの憧れで終わっていたことでしょう。

                            YUKI MAMIYA BALLET STUDIOは、いわゆる宝塚受験専用スクールではなく、受験対策もしてくれるバレエスタジオであるので、受験生以外の生徒さんもいて、それぞれの世界で頑張っている個人がバレエを通じて刺激しあえる環境だと、先日の発表会を拝見して再確認いたしました。舞台で踊っている生徒さん全員、生き生きと踊っていて、スタジオ開設から1年半しか経っていないとは思えない完成度でした。娘にとっては、宝塚音楽学校に合格してからも、親や学校以外で親身に相談にのって下さる恩師がいて、帰ってこられる場所があるのは本当に有難いと、親として大変感謝しております。`}
                    </Voice>

                    <Voice title="模擬試験の際に頂いた助言は大変有意義で、その後の指針となりました" from="宝塚受験クラス・合格者の保護者さま">
                        {`眞宮バレエスタジオの特色は大きく三つあると思います。週六日の多彩なプログラムと親身な指導に適した生徒数、そして最寄り駅から近いという高い利便性です。
                            娘はほぼ毎日スタジオへ通い、眞宮先生から直接レッスンを受けたことで技術が向上すると共に精神面の安定を得ることができ、加えて、各曜日の多様な講師の方からの指導を受けたことがさらなる自信に繋がったと思います。丁寧で温かみのある教えの下で、仲間との安定した人間関係も築けたようです。
                            眞宮バレエスタジオで約一年間集中して過ごしたことで、あきらめない心を養うことができたと思います。
                            また、模擬試験の際に試験官の皆様から頂いた助言は大変有意義で、その後の指針となりました。`}
                    </Voice>
                </div>
            </div>
        </PageLayout >
    )
}